import React from "react";
import { createGlobalStyle } from "styled-components";
// import styled from "styled-components";
// import { Element, scrollSpy, Events, Link } from "react-scroll";
import "../src/theme/colors";
import View from "./components/View/View";
import Menu from "./components/Menu/Menu";
import AboutUs from "./components/ContentView/AboutUs";
import { colors } from "./theme/colors";
import { data } from "./data";
import "./index.css";
import global from "./theme/global";
import Shareholders from "./components/ContentView/Shareholders";
import Rodo from "./components/ContentView/Rodo";
const GlobalStyle = createGlobalStyle`${global}`;

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "React"
    };
  }

  render() {
    return (
      <>
        <GlobalStyle />
        <div className={"container"}>
          <Menu />
          <div className={"view"}>
            <View
              name={"aboutUs"}
              backgroundColor={colors.screenFirst}
              ViewData={<AboutUs />}
            />
            <View
              name={"shareholder"}
              backgroundColor={colors.screenSecond}
              ViewData={<Shareholders />}
            />
            <View
              name={"thirdView"}
              backgroundColor={colors.screenThird}
              ViewData={<Rodo />}
            />
            {/*<View name={"fourthView"} backgroundColor={colors.screenFourth} />*/}
            {/*<View name={"fifthView"} backgroundColor={colors.screenFifth} />*/}
          </div>
        </div>
      </>
    );
  }
}

export default App;
