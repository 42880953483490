import React from "react";

export const Rodo = () => {
  return (
    <div className={"rodo"}>
      <h1>RODO</h1>
      <div className={"privacy"}>
        <ol>
          <li>
            Niniejszy dokument zawiera informacje dotyczące zasad przetwarzania
            danych osobowych przez ESGE spółka akcyjna z siedzibą w Krakowie
            (dalej: „ESGE”), na podstawie art. 13 rozporządzenia Parlamentu
            Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 roku w
            sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
            osobowych i w sprawie swobodnego przepływu takich danych oraz
            uchylenia dyrektywy 95/46/WE (dalej: „RODO”) oraz relewantnych
            przepisów prawa.
          </li>
          <li>
            ESGE przetwarza dane osobowe następujących kategorii osób:
            <ol>
              <li>
                osób fizycznych będących klientami ESGE lub potencjalnymi
                klientami ESGE;
              </li>
              <li>użytkowników witryny internetowej ESGE;</li>
              <li>
                osób fizycznych będących dostawcami produktów i usług dla ESGE
                oraz związanych z nimi innych osób – dalej: „Dane osobowe”.
              </li>
            </ol>
          </li>
          <li>
            Administratorem Danych osobowych jest ESGE, z którą można się
            skontaktować poprzez adres e-mail:{" "}
            <b>
              <a href="mailto:rodo@esge.pl">rodo@esge.pl</a>
            </b>{" "}
            lub telefonicznie pod numerem <b>+48 (12) 424 93 90</b>.
          </li>
          <li>
            Dane osobowe są lub mogą być przetwarzane:
            <ol>
              <li>
                w celu realizacji przedmiotu działalności ESGE w szczególności
                czynności związanych z najmem a także sprzedażą i kupnem
                nieruchomości – podstawą prawną jest przetwarzanie niezbędne do
                wykonania umowy, której stroną jest osoba, której dane dotyczą,
                lub do podjęcia działań na żądanie osoby, której dane dotyczą,
                przed zawarciem umowy (art. 6 ust 1 pkt b) RODO);
              </li>
              <li>
                w celach analitycznych, marketingu bezpośredniego oraz
                archiwalnych (dowodowych), a także dla zabezpieczenia danych
                osobowych na wypadek prawnej potrzeby wykazania faktów –
                podstawą prawną przetwarzania danych jest niezbędność
                przetwarzania dla realizacji prawnie uzasadnionego interesu
                Administratora (art. 6 ust. 1 lit. f RODO);
              </li>
              <li>
                w celach analitycznych i statystycznych służących poprawie
                stosowanych funkcjonalności i usług świadczonych za
                pośrednictwem witryny internetowej, zapewnienia sprawnego
                funkcjonowania witryny internetowej oraz wyjaśnienia
                okoliczności niedozwolonego korzystania z witryny internetowej,
                które wynikają z prawnie uzasadnionych interesów realizowanych
                przez ESGE, (art. 6 ust. 1 lit. f RODO);
              </li>
              <li>
                w celu przesyłania pocztą elektroniczną powiadomień, materiałów
                informacyjnych i okolicznościowych, udostępnianych przez ESGE –
                na podstawie Pani / Pana zgody (art. 6 ust. 1 lit. a).
              </li>
            </ol>
          </li>
          <li>
            W związku z przetwarzaniem Pan / Pani Danych osobowych, decyzje
            dotyczące Pana / Pani nie będą podejmowane w sposób zautomatyzowany
            tj. bez udziału człowieka.
          </li>
          <li>
            Dane osobowe mogą być przekazywane podmiotom przetwarzającym je na
            zlecenie i w imieniu Administratora na podstawie umów powierzenia
            przetwarzania danych osobowych, w tym dostawcom usług prawnych,
            usług informatycznych, podmiotom świadczącym na rzecz ESGE usługi
            księgowe i inne usługi związane z bieżącą działalnością.
          </li>
          <li>
            Dane osobowe nie będą przekazywane poza Europejski Obszar
            Gospodarczy.
          </li>
          <li>
            Okres przetwarzania danych osobowych:
            <ol>
              <li>
                Dane osobowe będą przetwarzane przez niezbędny do zrealizowania
                celu ich przetwarzania. Po zrealizowanie tego celu, okres
                wygaśnięciu przechowywania Danych osobowych może zostać
                przedłużony o okres przedawnienia roszczeń, jeżeli ich
                przetwarzanie będzie niezbędne dla dochodzenia ewentualnych
                roszczeń lub obrony przed takimi roszczeniami przez ESGE;
              </li>
              <li>
                W przypadku wyrażenia przez Panią / Pana zgody – przez okres
                niezbędny do realizacji tego celu, lecz nie dłużej niż do
                momentu cofnięcia Pani / Pana zgody lub wniesienia przez Panią /
                Pana sprzeciwu.
              </li>
            </ol>
          </li>
          <li>
            W związku z przetwarzaniem Danych osobowych przysługują Państwu
            następujące uprawnienia:
            <ol>
              <li>
                prawo żądania od Administratora dostępu do Danych osobowych
                (art. 15 RODO);
              </li>
              <li>
                prawo żądania od Administratora sprostowania Danych osobowych
                (art. 16 RODO);
              </li>
              <li>
                prawo żądania od Administratora usunięcia Danych osobowych (art.
                17 RODO);
              </li>
              <li>
                prawo żądania od Administratora ograniczenia przetwarzania
                Danych osobowych (art. 18 RODO);
              </li>
              <li>
                prawo do przenoszenia Danych osobowych do innego administratora,
                o ile przetwarzanie odbywa się w sposób zautomatyzowany a
                przeniesienie jest technicznie możliwe (art. 20 RODO);
              </li>
              <li>
                prawo do wniesienia sprzeciwu wobec przetwarzania Danych
                osobowych opartego na art. 6 ust. 1 lit. f RODO (gdy podstawą
                prawną przetwarzania danych jest niezbędność przetwarzania dla
                realizacji prawnie uzasadnionego interesu Administratora) (art.
                21 RODO);
              </li>
              <li>
                prawo do wniesienia skargi do organu nadzorczego w państwie
                członkowskim Unii Europejskiej, które jest miejscem zwykłego
                pobytu Państwa lub Osoby Fizycznej w przypadku uznania, iż
                przetwarzanie Danych osobowych narusza przepisy RODO (art. 77
                RODO) – przypadku Polski jest to Prezes Urzędu Ochrony Danych
                Osobowych;
              </li>
              <li>
                prawo do skutecznego środka ochrony prawnej przed sądem
                przeciwko prawnie wiążącej decyzji organu nadzorczego lub jeżeli
                organ nadzorczy nie rozpatrzył skargi Państwa lub nie
                poinformował Państwa w terminie trzech miesięcy o postępach lub
                efektach rozpatrywania skargi wniesionej zgodnie z art. 77 RODO
                (art. 78 RODO);
              </li>
              <li>
                prawo do skutecznego środka ochrony prawnej przed sądem
                przeciwko ESGE lub podmiotowi przetwarzającemu Dane osobowe w
                przypadku uznania, iż prawa przysługujące Państwu na mocy RODO
                zostały naruszone w wyniku przetwarzania Danych osobowych z
                naruszeniem przepisów RODO (art. 79 RODO).
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
};
export default Rodo;
