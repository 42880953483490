import styled from "styled-components";
import { fonts } from "../../theme/fonts";

const ViewStyled = styled.div`
  min-height: 1800px;
  display: flex;
  padding-top: 40px;
  @media (min-width: 769px) {
    padding-left: 56px;
    padding-right: 15px;
  }
  @media (max-width: 768px) {
    padding-left: 15px;
    height: auto;
  }
  ${(props) =>
    props.backgroundColor ? { backgroundColor: props.backgroundColor } : null}
  ${(props) =>
    props.backgroundImage ? { backgroundImage: props.backgroundImage } : null}
`;

export default ViewStyled;
