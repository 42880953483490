import styled from "styled-components";

const ShareholdersStyled = styled.div`
  padding-left: 15px;
  padding-top: 68px;
  @media (max-width: 768px) {
    margin-top: 68px;
    padding-top: 0px;
  }
  ul {
    margin-top: 24px;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    li {
      text-transform: none;
      line-height: 24px;
      letter-spacing: 0;
      font-size: 14px;
      a {
        text-decoration: none;
        color: #000066;
      }
      a:hover {
        color: lightgray;
      }
    }
  }
`;

export default ShareholdersStyled;
