import React from "react";
import AboutUsStyled from "./AbouteUsStyled";

const AboutUs = () => {
  return (
    <AboutUsStyled>
      <div className={"columns"}>
        <h1>
          ESGE spółka akcyjna gruntowa
          <br /> i budowlana S.A. w Krakowie
        </h1>

        <p>
          <b>Siedziba</b>: Kraków (30-440) ul. Jana Janowskiego 94
        </p>
        <p>Zarejestrowana w Rejestrze Przedsiębiorców prowadzonym przez</p>

        <p>
          Sąd Rejonowy dla Krakowa-Śródmieście w Krakowie, XI Wydział
          Gospodarczy KRS
        </p>

        <p>
          <b>KRS:</b> 0000219468
        </p>

        <p>
          <b>Kapitał zakładowy:</b> 100.000,00 PLN
        </p>

        <p>
          <b>REGON:</b>120197350
        </p>

        <p>
          <b>NIP:</b> 6792887587
        </p>

        <p>
          <b>Rachunek bankowy:</b> 13 2490 0005 0000 4530 1136 1361 (Bank Alior)
        </p>
        <p><b>Dane kontaktowe:</b></p>
        <p>
          <b>Adres e-mail:</b> sekretariat@esge.pl
        </p>
        <p>
          <b>Dane kontaktowe w związku z przetwarzaniem danych osobowych:</b>
        </p>
        <p>
          <b>Adres e-mail: </b>rodo@esge.pl
        </p>
        <p>
          <b>Numer telefonu:</b> +48 (12) 424 93 90
        </p>
      </div>
      <div className={"columns"} style={{ marginTop: "8px" }}>
        <img
          src={"/about_us_1.jpg"}
          alt={"krakow"}
          style={{
            boxShadow: "13px 17px 29px 1px rgba(89,84,89,0.63)",
            width: "100%"
          }}
        />
      </div>
    </AboutUsStyled>
  );
};

export default AboutUs;
