export const colors = {
  menu: "#000066",
  screenFirst: "#fff",
  screenSecond: "#fff",
  screenThird: "#fff",
  screenFourth: "#FFB74D",
  screenFifth: "#9966FF",
  fontColorMenu: "#888",
  homeImage: `url("krakow-4472321.jpg")`,
  logoColor: "#fff"
};
