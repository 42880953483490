import React from "react";
import ShareholdersStyled from "./ShareholdersStyled";

const Shareholders = () => {
  return (
    <ShareholdersStyled>
      <div className={"columnsCustom"}>
        <h1>Dla akcjonariuszy</h1>
        <p>ESGE Spółka Akcyjna Gruntowa i Budowlana S.A. z siedzibą w Krakowie informuje, iż w związku z nowelizacją przepisu art. 5 § 5 Kodeksu Spółek Handlowych, w celu zapewnienia Akcjonariuszom dostępu do informacji, została uruchomiona zakładka dla Akcjonariuszy, w której będą, na bieżąco, publikowane wymagane przez prawo lub Statut ogłoszenia pochodzące od Spółki.</p>
        <p>W razie pytań prosimy o kontakt adresem e-mail:{" "}<b><a href="mailto:sekretariat@esge.pl">sekretariat@esge.pl</a></b>.</p>
        {/*<h3>Terminarz:</h3>*/}
        <ul>
          <li>15.05.2020 –{" "}<a href={"/documents/ESGE_informacja_o_dematerializacji_akcji.docx"} target={"_blank"}>informacja o dematerializacji akcji</a></li>
          <li>15.05.2020 –{" "}<a href={"/documents/ESGE_badanie_za_2019_uchwala zarzadu_4_2020_zwolanie_WZA.pdf"} target={"_blank"}>ogłoszenie o zwołaniu przez zarząd Zwyczajnego Walnego Zgromadzenia Akcjonariuszy</a></li>
          <li>15.05.2020 –{" "}<a href={"/documents/MSiG_nr_94.2020_s.11_12_ESGE.pdf"} target={"_blank"}>publikacja w Monitorze Sądowym i Gospodarczym (Nr 94/2020 (5984), poz. 121608) ogłoszenia o zwołaniu Zwyczajnego Walnego Zgromadzenia Akcjonariuszy</a></li>
          <li>15.05.2020 –{" "}<a href={"/documents/ESGE_badanie_za_2019_uchwala zarzadu_4_2020_propozycja zmiany_statutu.pdf"} target={"_blank"}>ogłoszenie o zamierzonej zmianie statutu Spółki wraz z propozycjami zmian</a></li>
          <li>10.06.2020 –{" "}<a href={"/documents/esge_protokol_z_zwza_z_09_06_20_akt_notarialny_repetytorium_a_nr_5278.2020.pdf"} target={"_blank"}>protokół ze Zwyczajnego Walnego Zgromadzenia Akcjonariuszy z dnia 9 czerwca 2020 roku</a></li>
          <li>10.08.2020 –{" "}<a href={"/documents/ESGE_badanie_2019_uchwala_8_2020.pdf"} target={"_blank"}>uchwała rady nadzorczej nr 8/2020 z dnia 27 lipca 2020 roku w sprawie ustalenia tekstu jednolitego statutu Spółki</a></li>
          <li>10.08.2020 –{" "}<a href={"/documents/ESGE_badanie_za_2019_statut_spolki_ESGE_28_07_20.pdf"} target={"_blank"}>statut Spółki – tekst jednolity uwzględniający zmiany dokonane uchwałą nr 15/2020 Zwyczajnego Walnego Zgromadzenia Akcjonariuszy z dnia 9 czerwca 2020 roku uchwalony przez Radę Nadzorczą Spółki w trybie głosowania pisemnego Uchwałą nr 8/2020</a></li>
          <li>28.09.2020 –{" "}<a href={"/documents/esge-dematerializacja-akcji-msig189-2020-6079-z-28-09-20-pozycja-49759-1-wezwanie akcjonariuszy.pdf"} target={"_blank"}>publikacja w Monitorze Sądowym i Gospodarczym (Nr 189/2020 (6079) z 28 września 2020 roku pod pozycją 49759 ogłoszenia – pierwszego wezwania Spółki do akcjonariuszy do złożenia dokumentów akcji w Spółce o następującej treści: <br /> <i>„Zarząd „ESGE” Spółki Akcyjnej Gruntowej i Budowlanej S.A. w Krakowie („Spółka”) na mocy art.16 ustawy z 30.08.19 o zmianie ustawy-Kodeks Spółek Handlowych oraz uchwały zarządu Spółki nr 6/2020 z 07.09.20 wzywa akcjonariuszy Spółki do złożenia posiadanych dokumentów akcji w celu ich dematerializacji do dnia 31.12.20. </i> <br /> <i> Dokumenty akcji należy składać w kancelarii „Niżankowska, Szumielewicz, Szuster - Adwokaci i Radcowie Prawni” sp. p. pod adresem: Kraków (31-061), ul. Trynitarska 6/32 od poniedziałku do piątku w godzinach 9-17.</i><br /> <i> Złożenie dokumentów akcji odbędzie się za pisemnym pokwitowaniem. </i> <br /> <i>Niniejsze wezwanie jest pierwszym wezwaniem”.</i></a></li>
          <li>13.10.2020 –{" "}<a href={"/documents/ESGE_dematerializacja_akcji_MSiG_200_2020_(6090)_z_13_10_20_pozycja_53962_2_ezwanie_akcjonariuszy.pdf"} target={"_blank"}> publikacja w Monitorze Sądowym i Gospodarczym (Nr 200/2020 (6090) z 13 października 2020 roku pod pozycją 53962 ogłoszenia – drugiego wezwania Spółki do akcjonariuszy do złożenia dokumentów akcji w Spółce o następującej treści: <br /> <i> „Zarząd „ESGE” Spółki Akcyjnej Gruntowej i Budowlanej S.A. w Krakowie („<b>Spółka</b>”) na mocy art.16 ustawy z 30.08.19 o zmianie ustawy-Kodeks Spółek Handlowych oraz uchwały zarządu Spółki nr 6/2020 z 07.09.20 wzywa akcjonariuszy Spółki do złożenia posiadanych dokumentów akcji w celu ich dematerializacji do dnia 31.12.20. </i> <br /> <i> Dokumenty akcji należy składać w kancelarii „Niżankowska, Szumielewicz, Szuster - Adwokaci i Radcowie Prawni” sp. p. pod adresem: Kraków (31-061), ul. Trynitarska 6/32 od poniedziałku do piątku w godzinach 9-17. </i> <br /> <i> Złożenie dokumentów akcji odbędzie się za pisemnym pokwitowaniem. </i> <br /> <i>Niniejsze wezwanie jest drugim wezwaniem”.</i></a></li>
          <li>28.10.2020 –{" "}<a href={"/documents/ESGE-dematerializacja-akcji-MSiG-211-02020-(6101)-z-28-10-20-pozycja-58346-3-wezwanie-akcjonariuszy.pdf"} target={"_blank"}>publikacja w Monitorze Sądowym i Gospodarczym Nr 211 (6101) z 28 października 2020 roku pod pozycją 58346 ogłoszenia – trzeciego wezwania Spółki do akcjonariuszy do złożenia dokumentów akcji w Spółce o następującej treści: <br /> <i> „Zarząd „ESGE” Spółki Akcyjnej Gruntowej i Budowlanej S.A. w Krakowie („Spółka”) na mocy art.16 ustawy z 30.08.19 o zmianie ustawy-Kodeks Spółek Handlowych oraz uchwały zarządu Spółki nr 6/2020 z 07.09.20 wzywa akcjonariuszy Spółki do złożenia posiadanych dokumentów akcji w celu ich dematerializacji do dnia 31.12.20. </i> <br /> <i> Dokumenty akcji należy składać w kancelarii „Niżankowska, Szumielewicz, Szuster - Adwokaci i Radcowie Prawni” sp. p. pod adresem: Kraków (31-061), ul. Trynitarska 6/32 od poniedziałku do piątku w godzinach 9-17. </i> <br /> <i> Złożenie dokumentów akcji odbędzie się za pisemnym pokwitowaniem. </i> <br /> <i>Niniejsze wezwanie jest trzecim wezwaniem”.</i></a></li>
        </ul>
      </div>
      <div className={"columnsCustom"}>
        <ul>
          <li>13.11.2020 –{" "}<a href={"/documents/ESGE-dematerializacja-akcji-MSiG2222020-6112-z-131120-pozycja-62623-4-wezwanie-akcjonariuszy.pdf"} target={"_blank"}>publikacja w Monitorze Sądowym i Gospodarczym Nr 220 (6112) z 13 listopada 2020 roku pod pozycją 62623 ogłoszenia – czwartego wezwania Spółki do akcjonariuszy do złożenia dokumentów akcji w Spółce o następującej treści: <br /> <i> „Zarząd „ESGE” Spółki Akcyjnej Gruntowej i Budowlanej S.A. w Krakowie („Spółka”) na mocy art.16 ustawy z 30.08.19 o zmianie ustawy-Kodeks Spółek Handlowych oraz uchwały zarządu Spółki nr 6/2020 z 07.09.20 wzywa akcjonariuszy Spółki do złożenia posiadanych dokumentów akcji w celu ich dematerializacji do dnia 31.12.20. </i> <br /> <i> Dokumenty akcji należy składać w kancelarii „Niżankowska, Szumielewicz, Szuster - Adwokaci i Radcowie Prawni” sp. p. pod adresem: Kraków (31-061), ul. Trynitarska 6/32 od poniedziałku do piątku w godzinach 9-17. </i> <br /> <i> Złożenie dokumentów akcji odbędzie się za pisemnym pokwitowaniem. </i> <br /> <i>Niniejsze wezwanie jest czwartym wezwaniem”.</i></a></li>
          <li>25.05.2021 –{" "}<a href={"/documents/ESGE-badanie_za_2020_uchwala_zarzadu_3.2021_scan_MAP_24.05.21.pdf"} target={"_blank"}>uchwała zarządu z 14.05.2021 o zwołaniu Zwyczajnego Walnego Zgromadzenia Akcjonariuszy</a></li>
          <li>31.05.2021 –{" "}<a href={"/documents/ESGE_badanie_za_2020_ MSiG_103.2021_z_pozycja_34802_ogloszenie_o_WZA.pdf"} target={"_blank"}>publikacja w Monitorze Sądowym i Gospodarczym Nr 103 (6248) z dnia 31 maja 2021 roku pod pozycją 34802 (strona 17-18) ogłoszenia o zwołaniu Zwyczajnego Walnego Zgromadzenia Akcjonariuszy</a></li>
          <li>02.07.2021 –{" "}<a href={"/documents/ESGE-akt-notarialny-repertorium-a-nr-7237-2021-scan-APB-30-06-21.pdf"} target={"_blank"}>protokół ze Zwyczajnego Walnego Zgromadzenia Akcjonariuszy z dnia 29 czerwca 2021 roku.</a></li>
          <li>06.10.2021 –{" "}<a href={ "/documents/ESGE_-_uchwala_RN_nr_7.2021.pdf" } target={"_blank"}>uchwała rady nadzorczej nr 7/2021 z dnia 6 października 2021 roku w sprawie ustalenia tekstu jednolitego statutu Spółki</a></li>
          <li>06.10.2021 –{" "}<a href={ "/documents/ESGE_-_Statut_-_tekst_jednolity_po_zmianach_uchwala_WZA_nr_13.2021_z_29.06.21.pdf"} target={"_blank"}>statut Spółki – tekst jednolity uwzględniający zmiany dokonane uchwałą nr 13/2021 Zwyczajnego Walnego Zgromadzenia Akcjonariuszy z dnia 29 czerwca 2021 roku uchwalony przez Radę Nadzorczą Spółki w trybie głosowania pisemnego Uchwałą nr 7/2021</a></li>
          <li>06.06.2022 –{" "}<a href={ "/documents/ESGE_-_Uchwala_zarzadu_3.2022_-_zwolanie_WZA.pdf"} target={"_blank"}>uchwała zarządu z dnia 2 czerwca 2022 roku o zwołaniu Zwyczajnego Walnego Zgromadzenia Akcjonariuszy</a></li>
          <li>07.06.2022 –{" "}<a href={ "/documents/ESGE_-_badanie_za_2021_-_MSiG_109.2022_z_pozycja_30608__ogloszenie_o_WZA.pdf"} target={"_blank"}>publikacja w Monitorze Sądowym i Gospodarczym Nr 109 ( 6508) z dnia 7 czerwca 2022 roku pod pozycją 30608 ogłoszenia o zwołaniu Zwyczajnego Walnego Zgromadzenia Akcjonariuszy Spółki na dzień 30 czerwca 2022 roku</a></li>
          <li>30.06.2022 –{" "}<a href={ "/documents/ESGE_-_akt_notarialny_-_Repetytorium_A_nr_1087.2022.pdf"} target={"_blank"}>protokół ze Zwyczajnego Walnego Zgromadzenia Akcjonariuszy z dnia 30 czerwca 2022 roku</a></li>
          <li>05.07.2022 –{" "}<a href={ "/documents/ESGE_-_statut_2022_-_uchwala_RN_i_tekst_jednolity.pdf"} target={"_blank"}>uchwała rady nadzorczej nr 6/2022 z dnia 5 lipca 2022 roku w sprawie ustalenia tekstu jednolitego statutu Spółki wraz ze statutem Spółki – tekst jednolity uwzględniający zmiany dokonane uchwałą nr 14/2022 Zwyczajnego Walnego Zgromadzenia Akcjonariuszy z dnia 30 czerwca 2022 roku</a></li>
          <li>19.04.2023 –{" "}<a href={ "/documents/ESGE_-_uchwala_nr_2.2023_-_zwolanie_Walnego_Zgromadzenia.pdf"} target={"_blank"}>uchwała zarządu z dnia 17 kwietnia 2023 roku o zwołaniu Zwyczajnego Walnego Zgromadzenia Akcjonariuszy</a></li>
          <li>27.04.2023 –{" "}<a href={ "/documents/MSiG_Nr_80_(6731)_z_dnia_25_kwietnia_2023_roku.pdf"} target={"_blank"}>publikacja w Monitorze Sądowym i Gospodarczym Nr 80 (6731) z dnia 25 kwietnia 2023 roku pod pozycją 20548 (strona 17) ogłoszenia o zwołaniu Zwyczajnego Walnego Zgromadzenia Akcjonariuszy</a></li>
          <li>29.05.2023 –{" "}<a href={ "/documents/ESGE_protokol_rep._5938.2023_walnego_zgromadzenia_scan_KAN_29.05.23..pdf"} target={"_blank"}>protokół ze Zwyczajnego Walnego Zgromadzenia Akcjonariuszy z dnia 25 maja 2023 roku</a></li>

          <li>02.08.2024 –{" "}<a href={ "/documents/ESGE_uchwala_zarzadu_o_zwolaniu_zgromadzenia_akcjonariuszy_z_02_08_2024.pdf"} target={"_blank"}>uchwała zarządu z dnia 2 sierpnia 2024 roku o zwołaniu Zwyczajnego Walnego Zgromadzenia Akcjonariuszy</a></li>
          <li>08.08.2024 –{" "}<a href={ "/documents/ESGE_MSiG_154_2024.pdf"} target={"_blank"}>publikacja w Monitorze Sądowym i Gospodarczym Nr 154 (7056) z dnia 8 sierpnia 2024 roku pod pozycją 39210 ogłoszenia o zwołaniu Zwyczajnego Walnego Zgromadzenia Akcjonariuszy Spółki na dzień 13 września 2024 roku</a></li>
          
          <li>13.09.2024 –{" "}<a href={ "/documents/EGSE_-_WZA_-_protokol_rep._9944.2024_skan_KAK_17.09.24.pdf"} target={"_blank"}>protokół Zwyczajnego Walnego Zgromadzenia Akcjonariuszy; uchwała o przymusowym wykupie akcji w trybie art. 418 k.s.h.</a></li>
          

        </ul>
      </div>
    </ShareholdersStyled>
  );
};

export default Shareholders;
