import { css } from "styled-components";
import { fonts } from "./fonts";

export const global = () => {
  return css`
    p {
      font-size: 14px;
      font-family: ${fonts.content};
      color: #1b262c;
      font-weight: 300;
      letter-spacing: -0.1px;
      line-height: 29px;
      margin-top: 0;
      margin-bottom: 4px;
    }
    h1 {
      font-size: 29px;
      font-family: ${fonts.content};
      text-align: left;
      color: #1b262c;
      margin-top: 0px;
    }
    h3 {
      font-size: 20px;
      font-family: ${fonts.content};
      text-align: left;
      color: #1b262c;
      margin-top: 16px;
    }
    ul {
      list-style-type: none;
      padding-top: 80px;
      padding: 0;
      margin: 0;
      li {
        margin: 15px 0 15px 0;
        padding: 0 0 10px 0;
        list-style: none;
        font-size: 17px;
        font-weight: 400;
        line-height: 1.75em;
        text-transform: uppercase;
        letter-spacing: 2px;
        border-bottom: 1px solid #afa8ba;
        cursor: pointer;
        width: 100%;
      }
      li:hover {
        color: #7b7485;
      }
    }
    @media (min-width: 769px) {
      .view {
        margin-left: 300px;
      }
    }
    @media (max-width: 768px) {
      .view {
        margin-top: 36px;
        margin-right: 14px;
      }
      .rodo {
        padding-top: 68px;
      }
    }
    ol {
      counter-reset: item;
      font-size: 12px;
      font-family: ${fonts.content};
      color: black;
      font-weight: 300;
      margin-top: 0px;
      line-height: 20px;
    }
    ol ol {
      margin-left: 16px;
    }
    ol > li {
      counter-increment: item;
      color: black;
      margin-bottom: 10px;
      margin-left: -26px;
    }

    ol ol > li {
      display: block;
      color: black;
      margin-bottom: 5px;
    }

    ol ol > li:before {
      content: counters(item, ".") ". ";
      margin-left: -30px;
      color: black;
    }
    .privacy {
      @media (min-width: 992px) {
        columns: 2;
        column-rule: 1px solid lightblue;
        column-gap: 60px;
      }
    }
    .columns {
      width: 450px;
      float: right;
      margin-right: 30px;

      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 20px;
        margin-right: 0px;
      }
    }
    .columnsCustom {
      width: 450px;
      margin-right: 30px;
      float: left;
      @media (max-width: 768px) {
        width: auto;
        margin-right: 0;
        float: none;
      }
    }
    .rodo {
      padding-left: 15px;
      padding-top: 68px;
    }

    a:link {
      color: #000066;
      text-decoration: none;
    }
  `;
};

export default global;
