import React from "react";
import LogoStyled from "./LogoStyled";
import { data } from "../../data/index";

const Logo = () => {
  return (
    <LogoStyled>
      <h1>{data.logo}</h1>
    </LogoStyled>
  );
};

export default Logo;
