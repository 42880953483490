import React from "react";
import ViewStyled from "./ViewStyled";
import { Element } from "react-scroll";

class View extends React.Component {
  render() {
    const { backgroundColor, backgroundImage, name, ViewData } = this.props;
    return (
      <ViewStyled
        backgroundColor={backgroundColor}
        backgroundImage={backgroundImage}
      >
        <Element name={name}>{ViewData}</Element>
      </ViewStyled>
    );
  }
}

export default View;
