import React from "react";
import styled from "styled-components";
import { colors } from "../../theme/colors";
import { fonts } from "../../theme/fonts";

const MenuWrapper = styled.div`
  font-family: ${fonts.menu};
  color: white;
  position: fixed;
  top: 0;
  width: 253px;
  height: 100%;
  z-index: 1;
  display: block;
  background-color: ${colors.menu};
  .menu {
    position: absolute;
    right: 50px;
    top: 20px;
    outline: none;
    font-size: 35px;
    cursor: pointer;
  }
  @media (min-width: 769px) {
    padding: 60px 30px 60px 30px;
    .menu {
      display: none;
    }
  }
  @media (max-width: 768px) {
    padding: 15px;
    width: 100%;
    height: 40px;
    overflow: hidden;
    &.opened {
      height: auto;
    }
  }
  // footer {
  //   position: fixed;
  //   bottom: 0;
  //   left: 0;
  //   text-align: center;
  //   p {
  //     color: white;
  //     font-size: 7px;
  //   }
  // }
`;

export default MenuWrapper;
