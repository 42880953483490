
import styled from "styled-components";
import { colors } from "../../theme/colors";
import { fonts } from "../../theme/fonts";

const LogoWraper = styled.div`
  text-align: center;
  display: block;
  width: 100%;
  color: #fff;
  margin-bottom: 100px;
  h1 {
    color: ${colors.logoColor};
    font-size: 58px;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-family: ${fonts.logo};
    margin-top: 0;
    text-align: center;
  }
  @media (max-width: 768px) {
    h1 {
      font-size: 40px;
      margin-bottom: 30px;
    }
  }
`;

export default LogoWraper;
