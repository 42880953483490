import React, { useState } from "react";
import { Link } from "react-scroll";
import { Icon } from "antd";
import MenuWrapper from "./MenuWraper";
import Logo from "../LogoWraper/Logo";
import { data } from "../../data";

const Menu = () => {
  const [open, setOpen] = useState(false);

  function handleToggle() {
    setOpen(!open);
  }

  return (
    <MenuWrapper className={`${open ? "opened" : ""}`}>
      <Logo />
      <Icon
        className="menu"
        type={`${open ? "close" : "menu"}`}
        onClick={handleToggle}
      />
      <ul>
        <li>
          <Link
            to="aboutUs"
            spy={true}
            smooth={true}
            duration={1200}
            offset={-50}
            onClick={handleToggle}
          >
            {data.overlap.about}
          </Link>
        </li>
        <li>
          <Link
            to="shareholder"
            spy={true}
            smooth={true}
            duration={1200}
            offset={0}
            onClick={handleToggle}
          >
            {data.overlap.users}
          </Link>
        </li>
        <li>
          <Link
            to="thirdView"
            spy={true}
            smooth={true}
            duration={1200}
            offset={0}
            onClick={handleToggle}
          >
            {data.overlap.private}
          </Link>
        </li>
        {/*<li>*/}
        {/*  <Link*/}
        {/*    to="fourthView"*/}
        {/*    spy={true}*/}
        {/*    smooth={true}*/}
        {/*    duration={1000}*/}
        {/*    offset={0}*/}
        {/*    onClick={handleToggle}*/}
        {/*  >*/}
        {/*    {data.overlap.docs}*/}
        {/*  </Link>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*  <Link*/}
        {/*    to="fifthView"*/}
        {/*    spy={true}*/}
        {/*    smooth={true}*/}
        {/*    duration={1500}*/}
        {/*    offset={0}*/}
        {/*    onClick={handleToggle}*/}
        {/*  >*/}
        {/*    {data.overlap.news}*/}
        {/*  </Link>*/}
        {/*</li>*/}
      </ul>
      {/*<footer>*/}
      {/*  <p>&copy; Copyright 2020, Aleksandra Placek</p>*/}
      {/*</footer>*/}
    </MenuWrapper>
  );
};

export default Menu;
